import $ from 'jquery'

class OfferModal {
    constructor() {

        var offsetTop = $(".c-hero").offset().top + 300;

        $(window).scroll(function(){
            var scrollTop = $(window).scrollTop();
            if(scrollTop > offsetTop){
                $("body").addClass('iframe--is-visible');
            } else if(scrollTop < offsetTop) {
                $("body").removeClass('iframe--is-visible');
            }
        });
    }
}

export default OfferModal