import $ from 'jquery'

class ImageCaption {
    constructor() {

        this.elements = {
            imageCaptionClass: $('.c-text--label-for-image, .c-text--label-for-image--left, .c-text--label-for-image--with-margin'),
            imageCaptionClassleft: $('.c-text--label-for-image--left'),
            imageCaptionText: $('.so-widget-cn-core-text-widget'),
            imageCaptionForImage: $('.widget.label-for-image')
        }

        this.ctaAppend  =  $('.c-cta, .c-hero, .crop')

        this.ctaFind    =  this.ctaAppend.find('.c-cta__background, .c-hero__background, .c-image')

        this.ctaFind.append(this.ctaDataCaption)

        this.events()
    }

    events() {
        this.elements.imageCaptionClass.parent('').addClass("label-for-image");
        this.elements.imageCaptionClass.parent('').addClass("label-for-imag--left");
        this.elements.imageCaptionText.parent('').addClass("label-for-image");
        this.elements.imageCaptionForImage.parent('').addClass("label-for-image");
    }

    ctaDataCaption(event) {
        return $('<span class="c-caption--title">').text(jQuery (this).attr('data-caption'));
    }
}

export default ImageCaption