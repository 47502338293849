import $ from 'jquery'

class Navigation {
    constructor() {

        this.menuEl = $('#main-navigation')
        this.menuItem = this.menuEl.find('.menu__item--has-children')
        this.menuItem.append('<span class="menu__item--toggle"></span>')

        this.menuToggle = $('.menu__item--toggle')

        this.menuToggle.on('click', this.revealSubMenu)
    }

    revealSubMenu(event) {
        $(this).parent().toggleClass('is-active');
    }
    
}

export default Navigation