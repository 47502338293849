import $ from 'jquery'

class BookingBar {
    constructor() {

        this.elements = {
            bookingBarDate: $('.c-booking-bar__checkin .c-booking-bar__date'),
            amenitiesWrapper: $('.c-row--amenities .c-slider-wrapper'),
            bookingBarParent: $('.c-booking-bar'),
            amenitiesParent: $('.c-row--amenities .panel-grid-cell')
        }

        this.events()

    }

    events() {
        this.elements.bookingBarDate.attr("placeholder", "Check in...");
        this.elements.amenitiesWrapper.append( '<p class="carousel-status"></p>' );
        this.elements.bookingBarParent.parents(".panel-grid").addClass("booking");
        this.elements.amenitiesParent.parents(".panel-grid").addClass("amenities");
    }
}

export default BookingBar