import $ from 'jquery'

class ClampTarget {
    constructor() {
        var clampSelectors = [
            {
                cssSelector: '.c-grid-filter--cta .c-grid-filter__grid.grid-large-4 .c-grid-filter__item .c-cta--left .c-cta__text',
                lineLength: 3
            }
        ];
    
        $.each(clampSelectors, function(index, clamp) {
            $(clamp.cssSelector).each(function(index, element) {
                $clamp(element, {clamp: clamp.lineLength});
            });
        });
    }
}

export default ClampTarget