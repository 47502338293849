import $ from 'jquery'

class OffcanvasActivators {
    constructor() {

        this.elements = {
            gridFilterDropdown: $('select option:contains("Filter: All")')
        }

        this.bookButton =  $('.c-page-header__book')
        this.newsletterActivator =  $('.c-page-footer__button.newsletter')
        this.newsletterClose =  $('.c-newsletter-icon__box')

        this.bookButton.on('click', this.revealBookingModal)
        this.newsletterActivator.on('click', this.revealNewsletter)
        this.newsletterClose.on('click', this.hideNewsletter)

        this.events()
    }
    
    events() {
        this.elements.gridFilterDropdown.text('All');
    }

    revealBookingModal(event) {
        $('body').toggleClass('header-booking--is-active');
    }

    revealNewsletter(event) {
        $('.c-offcanvas-newsletter').addClass("is-active");
        $('body').addClass("offcanvas-newsletter-is-active");
        $('html').addClass("offcanvas-newsletter-is-active");
    }

    hideNewsletter(event) {
        $('.c-offcanvas-newsletter').removeClass("is-active");
        $('body').removeClass("offcanvas-newsletter-is-active");
        $('html').removeClass("offcanvas-newsletter-is-active");
    }
}

export default OffcanvasActivators