import OffcanvasNewsletter from './modules/OffcanvasNewsletter'
import ClampTarget from './modules/ClampTarget'
import Navigation from './modules/Navigation'
import ImageCaption from './modules/ImageCaption'
import OfferModal from './modules/OfferModal'
import BookingBar from './modules/BookingBar'
import OffcanvasActivators from './modules/OffcanvasActivators'

new OffcanvasNewsletter()
new ClampTarget()
new Navigation()
new ImageCaption()
new OfferModal()
new BookingBar()
new OffcanvasActivators()