import $ from 'jquery'

class OffcanvasNewsletter {
    constructor() {
        $(function() {
            var loc = window.location.href;
            if(/#newsletter/.test(loc)) {
              $('.c-offcanvas-newsletter').addClass('is-active');
            }
        });
    }
}

export default OffcanvasNewsletter